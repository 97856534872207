import React from "react"
import FotoNetCallPeru from "@components/pageCasosdeExito/image/imgPhotoEmpresa/netcall/FotoNetCallPeru.png"
import FotoNetCallPeru2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/netcall/FotoNetCallPeru2.png"

//logo de empresas clientes
import NetcallPeru from "@components/pageCasosdeExito/image/logoscustomer/NetcallPeru.svg"

//iconos de modulos
import llamadas from "@components/pageCasosdeExito/image/iconcards/llamadas.svg"
import cmr from "@components/pageCasosdeExito/image/iconcards/crm.svg"
import backoffice from "@components/pageCasosdeExito/image/iconcards/backoffice.svg"
import calidad from "@components/pageCasosdeExito/image/iconcards/calidad.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/netcall/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/netcall/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/netcall/img3.svg"

const text1 = (
  <span className="">
    Netcall Perú, grupo de empresas de capital peruano con más de 20 años de
    experiencia proveyendo servicios a la industria de las telecomunicaciones,
    usa Beex para sus operaciones de televentas de más de 400 operadores.
    <br /> <br />
    Beex, con su marcador Predictivo con Machine Learning y procesos
    especializados en televentas, ha logrado mejorar en más del 25% la
    contactabilidad de Netcall con sus clientes, logrando aumentar sus ventas
    mensuales a casi al doble.
  </span>
)

const text2 = (
  <span>
    Netcall Perú ayuda a las empresas de telecomunicaciones con sus servicios
    enfocados en 4 principales unidades de negocio: Comercialización de
    productos tecnológicos, Business Process Outsourcing (BPO), Contact Center y
    Venture Capital.
    <br />
    <br />
    A lo largo de más de 20 años de vida, ha logrado posicionarse como uno de
    los principales socios comerciales de las empresas líderes en el rubro de
    telecomunicaciones. Esto, incluso les ha permitido ser representantes
    exclusivos de marcas como Huawei y Umidigi.
    <br />
    <br />
    A través de su unidad de negocio de Contact Center, Netcall ayuda a las
    empresas de esta industria en posicionar de forma inteligente sus productos
    en el mercado peruano, iniciando con el contacto telefónico e incluyendo el
    journey de backoffice y postventa.
    <br />
    <br />
    Humberto Chumán, jefe de proyectos en Netcall Perú, ha sido uno de los
    actores principales en la implementación y desarrollo de Beex en su
    operación de televentas. Teniendo como principales objetivos el mejorar la
    contactabilidad y tener claras todas las fases de ventas en la plataforma.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    <span style={{ zIndex: "1", position: "sticky" }}> “Con Beex </span>
    <span
      style={{
        color: "transparent",
        display: "inline",
        left: "0px",
        position: "absolute",
      }}
    >“Con <span
      style={{
        boxShadow: " 0 -14px 0 #f7d731 inset",
     
      }}
    >Beex
    </span>
    </span>
    y su marcador predictivo, hemos mejorado la contactabilidad de nuestras
    bases y también otros indicadores importantes, como nuestra tasa de
    ocupación que mejoró en más del 20%.”
  </p>
)

const text4 = (
  <span>
    Sin duda, el principal objetivo planteado con Beex fue mejorar la
    contactabilidad con los clientes de Netcall, y para eso se puso en marcha la
    versión más reciente de su marcador predictivo, el cuál trabaja con Machine
    Learning y algoritmos de reconocimiento de voz que tienen un % de éxito
    mayor al 97%.
    <br />
    <br />
    El resultado fue mejor de lo esperado, Netcall logró aumentar en más del 25%
    su contactabilidad y con ello sus ventas. Pero no fue lo único que se
    planteo desde el inicio de la implementación, también era necesario tener
    todas las fases de ventas telefónicas en Beex, por lo que se integraron
    nuestros módulos de validaciones y backoffice.
  </span>
)

const text5 = (
  <span>
    “Hemos mejorado mucho nuestros tiempos de gestión dado que tenemos procesos
    automatizados de transferencia de llamadas desde el front al área de
    validación y backoffice según nuestras reglas de negocio.”
  </span>
)
const text6 = (
  <span>
    Además de esto, Beex ha brindado procesos que mejoran la gestión diaria.
    Tal como lo cuenta Humberto, se evidencia una evolución favorable en
    beneficio de los principales stakeholders, como administradores,
    supervisores y teleoperadores.
    <br /> <br />
    Por ejemplo, la optimización de los tiempos en su gestión de ventas se ha
    dado gracias a la visibilidad que brinda la plataforma, para supervisar y
    controlar su operación que es remota.
    <br /> <br />
    Los módulos de supervisión de usuarios y los visores en tiempo real de los
    marcadores telefónicos de Beex, han permitido que Netcall pueda administrar
    y gestionar sus campañas de televentas desde donde deseen, solo deben tener
    un dispositivo con un navegador web y conexión a internet.
    <br /> <br />
    Netcall cuenta con alertas en los tiempos muertos o en el ACW de su equipo,
    lo que les ha permitido tomar acciones automatizadas según estos casos. ¿El
    resultado? Mejorar su ratio de Adherencia en más del 95%.
  </span>
)

const text7 = (
  <span>
    “Actualmente, Netcall tiene operaciones 100% Home Office, y Beex es el
    software ideal para supervisar y administrar nuestra operación desde
    cualquier dispositivo y desde cualquier lugar.”
  </span>
)
const text8 = (
  <p>
    Con la pandemia, Netcall decidió migrar sus operaciones a Home Office y unos
    de los retos más importantes fue el monitorear y administrar sus diferentes
    campañas de forma eficiente.
    <br /> <br />
    Beex ayudó en esa tarea, ya que al ser un software nativamente cloud,
    brinda múltiples beneficios. Desde su implementación, la cuál se hizo en
    aproximadamente 15 días, hasta su administración posterior con todos los
    procesos ya mencionados.
    <br /> <br />
    Además, como es característica de nosotros, el acompañamiento con Netcall
    fue uno de los puntos que más se notan, y lo dice Humberto como experiencia
    propia:
  </p>
)
const text9 = (
  <span>
    “El tiempo y tipo de atención que nos brinda Beex Service Desk es
    buenísimo. Siempre se ponen en nuestro lugar y han resuelto nuestras dudas y
    consultas teniendo en cuenta la complejidad de las mismas.”
  </span>
)
const text10 = (
  <p>
    Y esto no era menos importante en una operación que cuenta con más de 400
    operadores, en donde cada minuto de gestión cuenta como tiempo productivo.
    <br /> <br />
    Estos son algunos de los procesos con los ayudamos a Netcall Perú en su operación de televentas:
  </p>
)
const text11 = (
  <p>
    La sinergia entre Beex y Netcall ha permitido que compartamos feedback
    que han sumado a sus operaciones y a nosotros como empresa de tecnología. 
    Estamos felices de tener un cliente con tan buena trayectoria y
    reputación en el mercado nacional.
  </p>
)

const data = {
  intro: {
    image: NetcallPeru,
    link: "https://www.youtube.com/watch?v=SxOvLFF8fGk",
    text1: text1,
    classlogo: "logo__clientes--kontigo",
    text2: text2,
    text3: text3,
    fotoone: FotoNetCallPeru,
    text4: text4,
    text5: text5,
    title6: "Todo se mide y se mejora",
    text6: text6,
    title7: "Una operación de televentas 100% Home Office",
    text7: text7,
    fototwo: FotoNetCallPeru2,
    text8: text8,
    text9: text9,
    text10: text10,
    text11: text11,
    
    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "Marcaciones telefónicas",
      img: llamadas,
      info:
        "Marcaciones progresivas y predictivas para llegar a mas clientes en sus horarios preferidos, con esto, aumentan la contactabilidad y las chances de cerrar las ventas de sus productos.",
    },
    {
      title: "CMR",
      img: cmr,
      info:
        "Registro total de clientes y productos en nuestro CRM, para registrar los pasos del viaje del cliente, desde el contacto hasta el backoffice.",
    },
    {
      title: "Backoffice",
      img: backoffice,
      info:
        "Módulos de validación y backoffice para escuchar y auditar las ventas realizadas en tiempo real, ya que cuentan con reglas claras de negocio, que se cumplen en los diferentes apartados de nuestros módulos validadores.",
    },
    {
      title: "Calidad",
      img: calidad,
      info:
        "Módulo y procesos de validación de ventas, pudiendo transferir en tiempo real las diferentes interacciones del cliente para que sean validadas por agentes especializados en estos skillls.",
    },
  ],
}

export default data
